// CanvasBackground.js
import React, { useEffect, useRef } from 'react';

const CanvasBackground = () => {
  const canvasRef = useRef(null);
  const mousePosition = useRef({ x: 0, y: 0 });
  const radius = useRef(0);
  const increasing = useRef(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const updateMousePosition = event => {
      mousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const draw = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(
        mousePosition.current.x,
        mousePosition.current.y,
        radius.current,
        0,
        Math.PI * 2,
        false
      );
      context.strokeStyle = 'rgba(0, 255, 182, 0.8)';
      context.lineWidth = 5;
      context.stroke();

      // Update radius
      if (increasing.current) {
        radius.current += 0.5;
        if (radius.current >= 30) {
          increasing.current = false;
        }
      } else {
        radius.current -= 0.5;
        if (radius.current <= 0) {
          increasing.current = true;
        }
      }

      requestAnimationFrame(draw);
    };

    window.addEventListener('mousemove', updateMousePosition);
    draw();

    // Resize canvas when window is resized
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
      }}
    />
  );
};

export default CanvasBackground;
