import styles from '../styles/connect.module.css';
import { FaRegHandshake } from 'react-icons/fa';
import React, { useState } from 'react';
import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { RiInstagramFill } from 'react-icons/ri';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { IoIosMail } from 'react-icons/io';
import data from '../data/template.json';
import emailjs from 'emailjs-com';
const Connect = () => {
  // template id template_luzc2bi
  // private key Y2T0wQeebY3kaTHc1F_A-
  // service id service_9ffnb5q
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const isValidEmail = email => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pattern.test(email)) {
      setEmailError(true);
    }
    return pattern.test(email);
  };
  const handleSubmit = event => {
    setError(false);
    setEmailError(false);
    event.preventDefault();
    if (email && name && message && isValidEmail(email)) {
      setSending(true);
      const templateParams = {
        name: name,
        sender_email: email,
        message: message,
      };

      emailjs
        .send(
          'service_9ffnb5q',
          'template_r5qb7ey',
          templateParams,
          'Zao1QNsis94Xp2eeh'
        )
        .then(response => {
          console.log('Email successfully sent!', response);
          // alert('Email sent successfully!');
          setEmail('');
          setMessage('');
          setName('');
          setSending(false);
        })
        .catch(err => {
          setSending(false);
          console.error('Failed to send email. Error: ', err);
          alert('Failed to send email.');
        });
    } else {
      setError(true);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:mukeshb.work@gmail.com';
  };
  return (
    <div id="connection_with_me_container" className={styles.container}>
      <div className="flex flex-col w-full justify-center items-center my-10 gap-10">
        <div className="text-2xl font-bold w-full text-center">Where can you  find me !!👇</div>
        <div className="flex  m-auto h-10  gap-8 justify-around">
        
        <a
          href={data.socialUrl.github ? data.socialUrl.github : null}
          className='text-center hover:scale-150 hover:text-[#30ebc9]'
        >
          {'Github'}
          <BsGithub color="red" className=" w-12 h-12" />
        </a>
        <a
          href={data.socialUrl.linkedin ? data.socialUrl.linkedin : null}
          className={` text-center hover:scale-150 hover:text-[#30ebc9]`}
        >
          {' Linkdin'}
          <BsLinkedin className=" w-12 h-12 text-blue-500" fill="#0077B5" />
        </a>
        {/* <a
          href={data.socialUrl.instagram ? data.socialUrl.instagram : null}
          className={`  `}
        >
          {' '}
          <RiInstagramFill className=" w-12 h-12" />
        </a> */}
        <a
          href={data.socialUrl.twitter ? data.socialUrl.twitter : null}
          className={` hover:scale-150 text-center hover:text-[#30ebc9] `}
        >
          {'Twitter '}
          <AiFillTwitterCircle className=" w-14 h-14" fill="#08a0e9"/>
        </a>
        <a href="mailto:mukeshb.work@gmail.com" onClick={handleClick} className='text-center hover:scale-150 hover:text-[#30ebc9]'>
          {'Mail'}
          <IoIosMail className="  w-12 h-12" />
        </a>
        <div
          className={`${styles.verticalStick} socialBarLine1AnimationClass`}
        ></div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <p className={styles.topic}>Send me a message !</p>
        <p className={styles.subheading}>
          Got a question or proposal, or just want to say hello? Go ahead.
        </p>
        <div className={styles.form}>
          <div className={styles.row}>
            <div>
              <label htmlFor="">Your Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div>
              <label htmlFor="">
                Email Address
              </label>

              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                required
              />
              {emailError && (
                  <div style={{ color: 'red',fontSize:"10px" }}>Fill Correct Email!</div>
                )}
            </div>
          </div>

          <div className={styles.column}>
            <label htmlFor="">Your Message</label>
            <input
              type="text"
              placeholder="Enter your Message"
              value={message}
              onChange={e => {
                setMessage(e.target.value);
              }}
              required
            />
          </div>
        </div>
        {error && <div style={{ color: 'red' }}>Fill Out All The Info!</div>}
        {sending && (
          <div style={{ color: 'green' }}>Sending Message to Mukesh....</div>
        )}

        <button className={styles.connect} onClick={e => handleSubmit(e)}>
          Connect{' '}
          <FaRegHandshake
            color="#63fbd7"
            style={{ transform: 'scale(1.2)', marginLeft: '20px' }}
          />
        </button>
      </div>
    </div>
  );
};
export default Connect;
