import { useEffect } from 'react';
import styles from '../styles/about.module.css';
import { FaReact } from 'react-icons/fa';
import { TbBrandNextjs } from 'react-icons/tb';
import { AiFillHtml5 } from 'react-icons/ai';
import { FaCss3Alt } from 'react-icons/fa';
import { SiTailwindcss } from 'react-icons/si';
import { SiJavascript } from 'react-icons/si';
import SliderDesign2 from './Motion'
import gsap from 'gsap';
const About = () => {
  useEffect(() => {
    // Animate the header with a 1-second delay and a duration of 2 seconds
    gsap.fromTo(
      '.aboutClass',
      { ScrollTrigger: '.aboutClass', y: '100vh',autoAlpha:0 }, // Starting from above the viewport
      { y: '0%', duration: 2,autoAlpha:1, delay: 1.5,  } // Ending at its initial position
    );
    gsap.fromTo(
      '.aboutClass1',
      { ScrollTrigger: '.aboutClass', y: '100vh',autoAlpha:0 }, // Starting from above the viewport
      { y: '0%', duration: 2 ,autoAlpha:1, delay: 2,  } // Ending at its initial position
    );
    gsap.fromTo(
      '.aboutClass2',
      { ScrollTrigger: '.aboutClass', y: '100vh',autoAlpha:0 }, // Starting from above the viewport
      { y: '0%', duration: 2,autoAlpha:1, delay: 2.5 } // Ending at its initial position
    );
    gsap.fromTo(
      '.aboutClass3',
      { ScrollTrigger: '.aboutClass', y: '100vh',autoAlpha:0 }, // Starting from above the viewport
      { y: '0%', duration:2,autoAlpha:1, delay: 3,  } // Ending at its initial position
    );
  }, []);
 

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={`${styles.heading} aboutClass`}>About me</p>
        {/* <SliderDesign2/> */}
        <p className={`${styles.bio} aboutClass1`}>
        Hey, I'm Mukesh, a passionate developer honing my skills at Army Institute of Technology, Pune. I thrive in the digital realm, crafting innovative solutions that bridge imagination with functionality. From breathing life into startups' tech dreams to fine-tuning user experiences for corporate giants, I'm your go-to guy. My toolkit? It's packed with Next.js, React.js, JavaScript, TypeScript, Tailwind CSS, and more – basically, everything needed to turn ideas into reality. But it doesn't stop there.
        </p>
        <p className={`${styles.bio} aboutClass2`}>
        I'm also diving deep into the world of end-to-end application testing, ensuring our creations not only look good but perform flawlessly. Ready to embark on this exciting journey of innovation with me?
        </p>
        <div className={`${styles.technology} aboutClass3`}>
          <FaReact
            className={styles.techIcons}
            size={'3rem'}
            color={'#ff0000'}
          />
          <TbBrandNextjs className={styles.techIcons} size={'3rem'} />
          <AiFillHtml5 className={styles.techIcons} size={'3rem'} />
          <FaCss3Alt className={styles.techIcons} size={'3rem'} />
          <SiTailwindcss className={styles.techIcons} size={'3rem'} />
          <SiJavascript className={styles.techIcons} size={'3rem'} />
        </div>
      </div>
    </div>
  );
};
export default About;
