import React from 'react';
import { motion } from 'framer-motion';
import Cicon from '../assets/icons8-c-240.png';
import Extensionicon from '../assets/icons8-extension-256.png'
import Giticon from '../assets/icons8-git-240.png'
import Githubicon from '../assets/icons8-github-256.png'
import Javascripticon from '../assets/icons8-javascript-240.png'
import Typescripticon from '../assets/icons8-typescript-240.png'
import Nextjsicon from '../assets/icons8-nextjs-240.png'
import Reacticon from '../assets/icons8-react-native-240.png';
import Tailwindicons  from '../assets/icons8-tailwind-css-240.png';


const slides = [
  { icon: <img src={Reacticon} className="hover:scale-125" alt="react-icon" /> },
  { icon: <img src={Tailwindicons}  alt="altText" /> },
  { icon: <img src={Nextjsicon}  alt="altText"/> },
  { icon: <img src={Typescripticon}  alt="altText"/> },
  { icon: <img src={Javascripticon}  alt="altText"/> },
  { icon: <img src={Githubicon}  alt="altText"/> },
  { icon: <img src={Giticon} alt="altText" /> },
  { icon: <img src={Extensionicon}  alt="altText"/> },
  { icon: <img src={Cicon}  alt="altText"/> },
];

const SliderDesign2 = () => {
  const duplicatedSlides = [...slides, ...slides];

  return (
    <div
      className="relative h-full overflow-hidden py-12  mx-auto"
      style={{ width: '100%' }}
    >
      <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r  before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l  after:to-transparent after:filter after:blur-3"></div>

      <motion.div
        className="flex"
        animate={{
          x: ['0%', '-100%'],
          transition: {
            ease: 'linear',
            duration: 15,
            repeat: Infinity,
          },
        }}
      >
        {duplicatedSlides.map((slide, index) => (
          <div
            key={index}
            className="flex-shrink-0"
            style={{ width: `${100 / slides.length}%` }}
          >
            <div className="flex items-center justify-center h-full p-4">
              {slide.icon}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default SliderDesign2;
