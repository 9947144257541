import styles from '../styles/socialBar.module.css';
import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { RiInstagramFill } from 'react-icons/ri';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { IoIosMail } from 'react-icons/io';
import { FaReact } from 'react-icons/fa';
import { TbBrandNextjs } from 'react-icons/tb';
import { IoLogoJavascript } from 'react-icons/io';
import { SiTypescript } from 'react-icons/si';
import { SiTailwindcss } from "react-icons/si";
import { SiMui } from "react-icons/si";
import { SiAwsamplify } from "react-icons/si";
import { SiApollographql } from "react-icons/si";
import { FaGithub } from "react-icons/fa";
import data from '../data/template.json';
import { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
// import { useGSAP } from '@gsap/react';

const SocialBar = () => {
  const [tempData, setTempData] = useState({ ...data });
  useEffect(() => {
    // Animate the header with a 1-second delay and a duration of 2 seconds
    gsap.fromTo(
      '.socialBar1AnimationClass',
      { y: '-100vh', visibility: 0 }, // Starting from above the viewport
      { y: '0%', duration: 2, delay: 0.5, ease: 'back.inOut' } // Ending at its initial position
    );
    gsap.fromTo(
      '.socialBarLine1AnimationClass',
      { y: '100vh', visibility: 0 }, // Starting from above the viewport
      { y: '0%', duration: 2, delay: 0.5, ease: 'back.inOut' } // Ending at its initial position
    );
  }, []);
  
    const handleClick = (e) => {
      e.preventDefault();
      window.location.href = 'mailto:mukeshb.work@gmail.com';
    };
  return (
    <div className="mainContainer">
      <div className={styles.container}>
        <a
          href={data.socialUrl.github ? data.socialUrl.github : null}
          className={styles.socialIcon}
        >
          {' '}
          <BsGithub color="red" className="socialBar1AnimationClass" />
        </a>
        <a
          href={data.socialUrl.linkedin ? data.socialUrl.linkedin : null}
          className={`${styles.socialIcon} socialBar1AnimationClass`}
        >
          {' '}
          <BsLinkedin />
        </a>
        <a
          href={data.socialUrl.instagram ? data.socialUrl.instagram : null}
          className={`${styles.socialIcon} socialBar1AnimationClass`}
        >
          {' '}
          <RiInstagramFill />
        </a>
        <a
          href={data.socialUrl.twitter ? data.socialUrl.twitter : null}
          className={`${styles.socialIcon} socialBar1AnimationClass`}
        >
          {' '}
          <AiFillTwitterCircle />
        </a>
        <a href="mailto:mukeshb.work@gmail.com" onClick={handleClick} className={styles.socialIcon}>
          {' '}
          <IoIosMail className="socialBar1AnimationClass" />
        </a>
        <div
          className={`${styles.verticalStick} socialBarLine1AnimationClass`}
        ></div>
      </div>
      <div className={styles.container2}>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <FaReact className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <TbBrandNextjs className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <IoLogoJavascript className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <SiTypescript className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <SiTailwindcss className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <SiMui className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <SiAwsamplify  className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <SiApollographql  className="socialBar1AnimationClass" />
        </div>
        <div className={styles.socialIcon}>
          {' '}
          {/* <BsGithub color="red" className='socialBar1AnimationClass' /> */}
          <FaGithub  className="socialBar1AnimationClass" />
        </div>
        <div
          className={`${styles.verticalStick} socialBarLine1AnimationClass`}
        ></div>
      </div>
    </div>
  );
};
export default SocialBar;
