import styles from '../styles/heroSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import HeroImage from '../assets/HeroImage.png';
import data from '../data/template.json';
import { gsap } from 'gsap';
import SliderDesign2 from './Motion';

const HeroSection = () => {
  const [tempData, setTempData] = useState({ ...data });
  useEffect(() => {
    // Animate the header with a 1-second delay and a duration of 2 seconds
    gsap.fromTo(
      '.testAnimationClass',
      { x: '-100%' }, // Starting from above the viewport
      { x: '0%', duration: 2, delay: 0, ease: 'bounce.out' } // Ending at its initial position
    );
    gsap.fromTo(
      '.test2AnimationClass',
      { x: '100%' }, // Starting from above the viewport
      { x: '0%', duration: 2, delay: 0, ease: 'bounce.out' } // Ending at its initial position
    );
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.subContainer} ${styles.column}`}>
        <div className={`${styles.heroText} testAnimationClass`}>
          <p className={styles.introText}>Hi ,I am </p>
          <p
            className={styles.name}
            onMouseEnter={() => {
              // SetCursor({ active: true });
            }}
            onMouseLeave={() => {
              // SetCursor({ active: false });
            }}
          >
            {tempData.name ? tempData.name : 'Povide name'}
          </p>
          <p className={styles.subHeading}>
            From dreamscapes to webscapes, I craft digital realities from your
            wildest ideas.
          </p>
          <p className={styles.bio}>
            From mechanical gears to coding frontiers: self-taught web developer
            crafting exceptional digital experiences.
          </p>
          <a
            href="#connection_with_me_container"
            className={styles.connectionButton}
          >
            Connect with me
            <FontAwesomeIcon
              className={styles.coffee}
              icon={faMugHot}
              size="xl"
            />
          </a>
        </div>
        <div className={`${styles.heroImageContainer} test2AnimationClass`}>
          <div className={styles.HeroImage}>
            <img
              src={
                tempData.profileImageUrl ? tempData.profileImageUrl : HeroImage
              }
              alt="heroImage"
            />
          </div>
        </div>
        <SliderDesign2 />
      </div>
    </div>
  );
};
export default HeroSection;
