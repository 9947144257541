import './App.css';
import {
  Header,
  SocialBar,
  HeroSection,
  About,
  Projects,
  WorkExp,
  Connect,
  Footer,
} from './components';
import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import CanvasBackground from './components/CanvasBackground';

const App = () => {
  const container = useRef();
  useEffect(() => {
    // Animate the header with a 1-second delay and a duration of 2 seconds
    gsap.fromTo(
      '.headerAnimationClass',
      { y: '-100%' }, // Starting from above the viewport
      { y: '0%', duration: 2, delay: 1 } // Ending at its initial position
    );
  }, []);
  // useGSAP(
  //   () => {
  //     gsap.from('.containerHeader', { y: '-100%', duration: 2, delay: 2 });
  //   },
  //   { scope: container }
  // );
  return (
    <>
      {/* // <CursorContextProvider> */}
      {/* <Cursor /> */}
      <CanvasBackground/>
      <div className="App" ref={container}>
        <SocialBar />
        <div className="headerAnimationClass">
          {/* Mukesh here */}
          <Header className="headerAnimationClass" />
        </div>
        <div>
          <HeroSection />
        </div>
        <div>
          <About />
        </div>

        <WorkExp />
        {/* <Projects /> */}
        <Connect />
        {/* <Footer/> */}
      </div>
      {/* // </CursorContextProvider> */}
    </>
  );
};

export default App;
